const challenges = () => {
  return [
    {
      title: "Stats preview card component",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/stats-card-component_KyFi3H-naiwk.webp?updatedAt=1640717038585",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/3-column-card-component",
      web: "https://3-column-card-component-jose-angel-rey.vercel.app/",
    },
    {
      title: "3-column card component",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/3-column-card-component_13zrgw-Xi8.webp?updatedAt=1640717038669",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/Insure-landing-page",
      web: "https://insure-landing-page-jose-angel-rey.vercel.app/",
    },
    {
      title: "Social proof section",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/social-proof-section_U3187tQf7.webp?updatedAt=1640717038421",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/Social-proof-section",
      web: "http://social-proof-section-jose-angel-rey-git-main.jose-angel-rey.vercel.app/",
    },
    {
      title: "Ping single column coming soon page",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/ping-single-column-coming-soon-page_MoVLa4M5F.webp?updatedAt=1640717038424",
      techs: "HTML - CSS - JavaScript",
      repository:
        "https://github.com/Jose-Angel-Rey/Ping-single-column-coming-soon-page",
      web: "https://ping-single-column-coming-soon-page-jose-angel-rey.vercel.app/",
    },
    {
      title: "Price grid component",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/price-grid-component_prMR7NMaUKl.webp?updatedAt=1640717038319",
      techs: "HTML - CSS",
      repository:
        "https://github.com/Jose-Angel-Rey/Single-price-grid-component",
      web: "https://single-price-grid-component-jose-angel-rey.vercel.app/",
    },
    {
      title: "Fylo data storage component",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/fylo-data-storage-component_M4PtTemZ-.webp?updatedAt=1640717040425",
      techs: "HTML - CSS",
      repository:
        "https://github.com/Jose-Angel-Rey/Fylo-data-storage-component",
      web: "http://fylo-data-storage-component-git-main.jose-angel-rey.vercel.app/",
    },
    {
      title: "Huddle landing page",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/huddle-landing-page-with-introductory-section_4Nx-r6bLT.webp?updatedAt=1640717041316",
      techs: "HTML - CSS",
      repository:
        "https://github.com/Jose-Angel-Rey/Huddle-landing-page-with-a-single-introductory-section",
      web: "http://huddle-landing-page-with-a-single-introductory-section-git-main.jose-angel-rey.vercel.app/",
    },
    {
      title: "Four card feature section",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/four-card-feature-section_KHZl6-jsS.webp?updatedAt=1640717040226",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/Four-card-feature-section",
      web: "https://four-card-feature-section-jose-angel-rey.vercel.app/",
    },
    {
      title: "Base Apparel coming soon page",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/base-apparel-coming-soon-page_iahg3lSwSi.webp?updatedAt=1640717039018",
      techs: "HTML - CSS - JavaScript",
      repository:
        "https://github.com/Jose-Angel-Rey/Base-apparel-coming-soon-page",
      web: "https://base-apparel-coming-soon-page-jose-angel-rey.vercel.app/",
    },
    {
      title: "Article preview component",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/article-preview-component_6weoqAMzWkIC.webp?updatedAt=1640717038962",
      techs: "HTML - CSS - JavaScript",
      repository: "https://github.com/Jose-Angel-Rey/article-preview-component",
      web: "https://article-preview-component-jose-angel-rey.vercel.app/",
    },
    {
      title: "FAQ accordion card",
      dificulty: "Newbie",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/faq-accordion-card_VxQEEqE2lbhi.webp?updatedAt=1640717039981",
      techs: "HTML - SASS - JavaScript",
      repository: "https://github.com/Jose-Angel-Rey/FAQ-accordion-card",
      web: "https://faq-accordion-card-jose-angel-rey.vercel.app/",
    },











    {
      title: "Fylo dark theme landing page",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/fylo-dark-theme-landing-page-master-jose-angel-rey.vercel.app__6eXxMPdtnJS.webp?updatedAt=1640718066742",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/Fylo-dark-theme-landing-page",
      web: "https://fylo-dark-theme-landing-page-master.jose-angel-rey.vercel.app/",
    },
    {
      title: "Clipboard landing page",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/clipboard-landing-page_w_yncTI0U.webp?updatedAt=1640717039552",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/Clipboard-landing-page",
      web: "http://clipboard-landing-page-master-git-main.jose-angel-rey.vercel.app/",
    },
    {
      title: "Testimonials grid section",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/testimonials-grid-section_Xrhb9pRFzc.webp?updatedAt=1640717038600",
      techs: "HTML - CSS",
      repository:
        "https://github.com/Jose-Angel-Rey/Testimonials-grid-section-main",
      web: "http://testimonials-grid-section-main-git-main.jose-angel-rey.vercel.app/",
    },
    {
      title: "Huddle landing page",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/huddle-landing-page_2vNvz7yeBy.webp?updatedAt=1640717040950",
      techs: "HTML - CSS",
      repository:
        "https://github.com/Jose-Angel-Rey/Huddle-landing-page-with-alternating-feature-blocks",
      web: "http://huddle-landing-page-with-alternating-feature-blocks-master.jose-angel-rey.vercel.app/",
    },

    {
      title: "Coding bootcamp testimonials slider",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/coding-bootcamp-testimonials-slider_QkzuzUwUx8.webp?updatedAt=1640717039807",
      techs: "HTML - CSS - JavaScript",
      repository:
        "https://github.com/Jose-Angel-Rey/coding-bootcamp-testimonials-slider",
      web: "https://coding-bootcamp-testimonials-slider-jose-angel-rey.vercel.app/",
    },

    {
      title: "Huddle landing page with curved sections",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/huddle-landing-page-with-curved-sections_dQEUEcTCbzz.webp?updatedAt=1640717041169",
      techs: "HTML - SASS",
      repository:
        "https://github.com/Jose-Angel-Rey/Huddle-landing-page-with-curved-sections",
      web: "https://huddle-landing-page-with-curved-sections-master.jose-angel-rey.vercel.app/",
    },
    {
      title: "Fylo landing page with two column layout",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/fylo-landing-page-with-two-column-layout_2JP0iND_xjie.webp?updatedAt=1640717040667",
      techs: "HTML - CSS",
      repository:
        "https://github.com/Jose-Angel-Rey/Fylo-landing-page-with-two-column-layout",
      web: "https://fylo-landing-page-with-two-column-layout-master.jose-angel-rey.vercel.app/",
    },
    {
      title: "Sunnyside agency landing page",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/sunnyside-agency-landing-page-jose-angel-rey.vercel.app___1__5bNQvjDfz.webp?updatedAt=1640718066784",
      techs: "HTML - SASS - JavaScript",
      repository:
        "https://github.com/Jose-Angel-Rey/Sunnyside-agency-landing-page",
      web: "https://sunnyside-agency-landing-page-jose-angel-rey.vercel.app/",
    },
    {
      title: "Insure landing page",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/insure-landing-page_mEHKvplrri.webp?updatedAt=1640717041491",
      techs: "HTML - SASS - JavaScript",
      repository: "https://github.com/Jose-Angel-Rey/Insure-landing-page",
      web: "https://insure-landing-page-jose-angel-rey.vercel.app/",
    },
    {
      title: "Blogr landing page",
      dificulty: "Junior",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/blogr-landing-page-jose-angel-rey.vercel.app__iFveNgSQp.webp?updatedAt=1640718066708",
      techs: "HTML - SASS - JavaScript",
      repository: "https://github.com/Jose-Angel-Rey/Blogr-landing-page",
      web: "https://blogr-landing-page-jose-angel-rey.vercel.app/",
    },
















    {
      title: "Easybank landing page",
      dificulty: "Intermediate",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/easybank_kQaqMRc0R.png?updatedAt=1640718111306",
      techs: "HTML - SASS - JavaScript",
      repository: "https://github.com/Jose-Angel-Rey/Easybank-landing-page",
      web: "https://easybank-landing-page-jose-angel-rey.vercel.app/",
    },

    {
      title: "Chat app CSS illustration",
      dificulty: "Intermediate",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frontend_Mentor/chat-app-css-illustration_p7QcbBH8Wgq.webp?updatedAt=1640717039398",
      techs: "HTML - CSS",
      repository: "https://github.com/Jose-Angel-Rey/Chat-app-css-illustration",
      web: "https://chat-app-css-illustration-jose-angel-rey.vercel.app/",
    },
  ];
};

export default challenges().reverse();
