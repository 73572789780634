import styles from "./Header.module.scss";
import logo from "../../assets/logo.svg";

export default function Header({ title }) {

  return (
    <header className={styles.container}>
      <img
        src={logo}
        alt="logo"
        data-aos="fade-up"
        data-aos-delay="1200"
        data-aos-duration="2000"
      />
      <h1 data-aos="fade-right" data-aos-delay="1800" data-aos-duration="2000">
        {title}
      </h1>
    </header>
  );
}
