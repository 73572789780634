// Routes
import { Route, Routes, useLocation } from "react-router-dom";
import Contact from "./routes/Contact/Contact";
import Category from "./routes/Category/Category";
import Home from "./routes/Home/Home";
import Projects from "./routes/Projects/Projects";
import Navbar from "./components/Navbar/Navbar";
import About from "./routes/About/About";
import Skills from "./routes/Skills/Skills";
import React, { useState } from "react";
import NotFound from "./routes/NotFound/NotFound";

// Animations
import { AnimatePresence, motion } from "framer-motion";
import AOS from "aos";
import { categoryVariants, mainVariants } from "./motion/variants";
import { categoryTransition, mainTransition } from "./motion/transitions";
import Alert from "./components/Alert/Alert";

AOS.init({
  duration: 1500,
  once: true,
  delay: 1000,
  debounceDelay: 0,
  throttleDelay: 10,
});

function App() {
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(true);

  return (
    <div style={{ overflowX: "hidden", position: "relative" }}>
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        {showAlert && (
          <Alert
            title={"Hi there!"}
            content={
              "I'm currently working on a new version of my personal portfolio. It will be available soon :)"
            }
            handleClose={() => setShowAlert(false)}
          />
        )}
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home motion={motion} />} />
          <Route
            path="/about"
            element={
              <About
                motion={motion}
                pageVariants={mainVariants}
                pageTransition={mainTransition}
              />
            }
          />
          <Route
            path="/skills"
            element={
              <Skills
                motion={motion}
                pageVariants={mainVariants}
                pageTransition={mainTransition}
              />
            }
          />
          <Route
            path="/projects"
            element={
              <Projects
                motion={motion}
                pageVariants={categoryVariants}
                pageTransition={categoryTransition}
              />
            }
          />
          <Route
            path="/projects/:category"
            element={
              <Category
                motion={motion}
                pageVariants={mainVariants}
                pageTransition={mainTransition}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact
                motion={motion}
                pageVariants={mainVariants}
                pageTransition={mainTransition}
              />
            }
          />
          <Route
            path="*"
            element={
              <NotFound
                motion={motion}
                pageVariants={mainVariants}
                pageTransition={mainTransition}
              />
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
