import styles from "./SkillsGrid.module.scss";

export default function SkillsGrid({ title, techs }) {
  return (
    <section
      className={styles.container}
    >
      <div className={styles.title}>
        <h2>{title}</h2>
      </div>
      <div className={styles.grid}>
        {techs.map((tech) => {
          return (
            <div className={styles.gridItem} key={tech.name}>
              <img src={tech.icon} alt={tech.name} />
              <h3>{tech.name}</h3>
            </div>
          );
        })}
      </div>
    </section>
  );
}
