import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";
import spaceShip from "../../assets/404/space-ship.svg";
import body from "../../assets/404/body.svg";

export default function NotFound({ motion }) {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "100vw",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "100%",
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 2.25,
  };

  return (
    <motion.main
      className={styles.notFoundPage}
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className={styles.notFoundPageIllustration}>
        <img src={spaceShip} alt="space-ship" />
        <img src={body} alt="body" />
      </div>
      <section className={styles.notFoundPageContent}>
        <h1>Page not found</h1>
        <Link to="/">Go to home page</Link>
      </section>
    </motion.main>
  );
}
