import styles from "./Category.module.scss";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";

// Projects data
import challenges from "../../data/frontend-mentor-challenges";
import fundamentals from "../../data/fundamentals-projects";
import fullStack from "../../data/full-stack-projects";
import Slider from "../../components/Slider/Slider";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

export default function Projects({ motion, pageVariants, pageTransition }) {
  const { state } = useLocation();
  const { category } = useParams();

  const checkCategory = (category) => {
    const categories = {
      "Frontend Mentor Challenges": challenges.map((project) => (
        <ChallengeCard {...project} />
      )),
      Fundamentals: fundamentals.map((project) => <ProjectCard {...project} />),
      "Javascript Frameworks": fullStack.map((project) => (
        <ProjectCard {...project} />
      )),
    };
    return categories[category];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={styles.container}
    >
      <Header title={state} />
      <section
        className={styles.content}
        data-aos="fade-up"
        data-aos-delay="2500"
        data-aos-duration="2000"
      >
        <Slider projects={checkCategory(category)} />
      </section>
    </motion.main>
  );
}
