import styles from "./EducationCard.module.scss";

export default function EducationCard({
  title,
  role,
  date,
  description,
  image,
}) {
  return (
    <article className={styles.container}>
      <header className={styles.header}>
        <img className={styles.headerLogo} src={image} alt={title} />
        <h3 className={styles.headerTitle}>
          {`${title} - ${date}`} <strong>{role}</strong>
        </h3>
      </header>
      <p className={styles.description}>{description}</p>
    </article>
  );
}
