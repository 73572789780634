import styles from "./About.module.scss";
import { FaDownload } from "react-icons/fa";
import { useEffect, useState } from "react";
import FancyHeader from "../../components/FancyHeader/FancyHeader";
// import gsap from "gsap";
import EducationCard from "../../components/EducationCard/EducationCard";
import HobbieCard from "../../components/HobbieCard/HobbieCard";

export default function About({ motion, pageVariants, pageTransition }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={styles.container}
    >
      <section className={styles.content}>
        <FancyHeader />
        <div
          className={styles.animationContainer}
          data-aos="fade-up"
          data-aos-duration="2500"
          data-aos-delay="3000"
        >
          <section className={styles.personal}>
            <article className={styles.personalDescription}>
              <p>
                Hi, I'm Jose Angel Rey, a Venezuelan Frontend Developer and
                passionate about constantly learning about the world of
                technology and web development.
              </p>
              <p>
                I like to learn something new every day and I'm always looking
                for a challenge with which I can learn something new or learn a
                better way to do something I already know and keep improving
                constantly.
              </p>
              <p>
                I like the topics related to design, user interfaces and the
                direction that frontend development is taking with the many
                trends and technologies that are coming out and updating, so I'm
                looking to specialize in frontend development but I'm always
                open to learn new things.
              </p>
              <p>
                My goal is to be part of a company where I can contribute my
                knowledge and have the opportunity to continue growing both
                personally and professionally.
              </p>
              <a
                className={styles.button}
                href="https://drive.google.com/drive/folders/1GTet7u85rfD700qXn6mG7KCiZpVs0yTS?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDownload /> Download CV
              </a>
            </article>
            <img
              onLoad={() => setIsLoaded(true)}
              className={
                styles.personalImage +
                " " +
                (isLoaded && styles.personalImageLoaded)
              }
              // src="https://ik.imagekit.io/8k98kll7xyh/Portfolio/Jose-Angel-Rey-3?updatedAt=1639872589430&tr=w-494,h-657,fo-custom,cm-extract"
              src="https://ik.imagekit.io/8k98kll7xyh/Portfolio/About_me/Jose-Angel-Rey-3?updatedAt=1640718231901"
              alt="Jose Angel Rey"
            />
          </section>

          <section className={styles.education}>
            <h2 className={styles.subTitle}>Education</h2>
            <div className={styles.flexContainer}>
              <EducationCard
                title="Henry Bootcamp"
                role="Full Stack Developer"
                date="2021"
                description="Intensive programming bootcamp of +800 theoretical and practical hours that simulates a real working environment and prepares you for a successful career as a Full Stack developer through projects using frontend and backend technologies."
                image="https://ik.imagekit.io/8k98kll7xyh/icons/henry_zv2tG3Qb-R.svg?updatedAt=1640601827358"
              />
              <EducationCard
                title="FreeCodeCamp"
                role="Responsive Web Design"
                date="2021"
                description="Certification in responsive web design using technologies such as HTML (Hypertext Markup Language) for the content, and CSS (Cascading Style Sheets) for the design of projects with modern techniques for the development of responsive websites."
                image="https://ik.imagekit.io/8k98kll7xyh/icons/freecodecamp_KTGDOYeQqyj.svg?updatedAt=1640601827173"
              />
              <EducationCard
                title="Self-taught"
                role="Web Development"
                date="Forever"
                description="I am constantly improving my web development skills in a self-taught way through resources such as MDN (Mozilla Developer Network), FreeCodeCamp, Udemy, Youtube and official documentation."
                image="https://ik.imagekit.io/8k98kll7xyh/icons/code_juuUdpVYI.svg?updatedAt=1640601827200"
              />
            </div>
          </section>

          <section className={styles.hobbies}>
            <h2 className={styles.subTitle}>Hobbies</h2>

            <div className={styles.flexContainer}>
              <HobbieCard title="Play Video Games" icon="gaming" />
              <HobbieCard
                title="Learn topics about graphic design"
                icon="design"
              />
              <HobbieCard title="Watch travel videos" icon="travel" />
              <HobbieCard title="Practice English" icon="languajes" />
              <HobbieCard title="Walking outdoors" icon="walking" />
              {/* <HobbieCard title="Learn new topics in general" icon="studying" /> */}
            </div>
          </section>
        </div>
      </section>
    </motion.main>
  );
}
