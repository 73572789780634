import styles from "./ChallengeCard.module.scss";

import { FaGithub } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";

export default function ChallengeCard({
  title,
  dificulty,
  techs,
  repository,
  web,
  image,
}) {
  return (
    <article className={styles.container}>
      <img src={image} alt={title} className={styles.image} />
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.info}>
        <p>
          <span>Difficulty:</span> {dificulty}
        </p>
        <p>
          <span>Techs:</span> {techs}
        </p>
      </div>
      <div className={styles.buttons}>
        <a rel="noopener noreferrer" target="_blank" href={repository}>
          <FaGithub /> Repository
        </a>
        <a rel=" noopener noreferrer" target="_blank" href={web}>
          <GrLanguage /> Live Demo
        </a>
      </div>
    </article>
  );
}
