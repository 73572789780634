import { NavLink } from "react-router-dom";
import styles from "./Navbar.module.scss";
import {
  FaHome,
  FaUserAstronaut,
  FaCode,
  FaBriefcase,
  FaEnvelope,
} from "react-icons/fa";
import { useEffect, useRef } from "react";
import gsap from "gsap";

export default function Navbar() {
  let navbar = useRef(null);

  const timeline = gsap.timeline();

  useEffect(() => {
    window.scrollTo(0, 0);
    timeline.from(
      navbar.current,
      {
        delay: 3,
        duration: 1.5,
        opacity: 0,
        y: window.innerWidth >= 1024 ? -10 : 10,
      },

      "1"
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <nav
      ref={navbar}
      className={styles.container}
      // data-aos={window.innerWidth >= 1024 ? "fade-down" : "fade-up"}
      // data-aos="fade-up"
      // data-aos-duration="3000"
      // data-aos-delay="3000"
      // data-aos-once="true"
    >
      <ul className={styles.nav}>
        <li className={styles.navItem}>
          <NavLink className={styles.navLink} to="/">
            <FaHome />
            <span>Home</span>
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink className={styles.navLink} to="/about">
            <FaUserAstronaut />
            <span>About</span>
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink className={styles.navLink} to="/skills">
            <FaCode />
            <span>Skills</span>
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink className={styles.navLink} to="/projects">
            <FaBriefcase />
            <span>Projects</span>
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink className={styles.navLink} to="/contact">
            <FaEnvelope />
            <span>Contact</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
