// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// eslint-disable-next-line
import "swiper/css/bundle";
import "./styles.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
} from "swiper";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Autoplay, Pagination, Navigation]);

export default function Slider({ projects }) {
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      className="mySwiper"
      spaceBetween={30}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
       pagination={{
         clickable: true,
       }}
      // pagination={true}
      // navigation={window.innerWidth > 767 ? true : false}
      // navigation={false}
    >
      {projects.map((project, index) => (
        <SwiperSlide key={index}>{project}</SwiperSlide>
      ))}
    </Swiper>
  );
}
