const projects = () => {
  return [
    {
      title: "THE GAMEROOM",
      description:
        "Single Page Application (SPA) focused on consuming information from an external API related to video games, the application has multiple functionalities such as pagination, detail views, filters by genre or origin, sorting based on certain criteria, allows the creation of a new game and has an responsive design.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/THE-GAMEROOM/mobile_HSm2XxOd_Va.png?updatedAt=1641380436753",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/THE-GAMEROOM/tablet_CQwnF02Lfa.png?updatedAt=1641380436852",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/THE-GAMEROOM/desktop_pDj11TDOs.png?updatedAt=1641380437951",
      },
      techs: [
        "SASS",
        "React",
        "Redux",
        "Node",
        // "Express",
        "PostgreSQL",
        "Sequelize",
      ],
      repository: "https://github.com/Jose-Angel-Rey/Videogames-App",
      web: "https://the-gameroom.vercel.app/",
    },
    {
      title: "E-commerce",
      description:
        "Participating in an agile development team with week-long sprints presenting to a Product Owner progress on the development of an airline ticket e-commerce  in which I developed frontend features using Sass, React and Redux.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Dev-Sky/mobile_wTJH81W_EvC.png?updatedAt=1641380266984",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Dev-Sky/tablet_RhSRI_r-s.png?updatedAt=1641380267147",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Dev-Sky/desktop_LozAOozg0A.png?updatedAt=1641380267756",
      },
      techs: ["SASS", "React", "Redux", "Node", "Express", "Firebase"],
      repository: "https://dev-sky.vercel.app/",
      web: "https://dev-sky.vercel.app/",
      buttons: {
        repository: false,
        web: true,
      },
    },
    {
      title: "GIPHY clone",
      description:
        "Single Page Application (SPA) focused on consuming data from the GIPHY API and managing the data making use of technologies such as Sass, React, Redux and Typescript on the frontend with Typescript, Node and Express for the development of a REST API on the backend.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/GIPHO/mobile_mJs4PdhK-.png?updatedAt=1641379851350",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/GIPHO/tablet_NcXuoUAQ4lT.png?updatedAt=1641379851228",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/GIPHO/desktop_iUZgCNP4wRpd.png?updatedAt=1641379851626",
      },
      techs: ["SASS", "Typescript", "React", "Redux", "Node", "Express"],
      repository: "https://github.com/Jose-Angel-Rey/GIPHY-clone",
      web: "https://gipho.vercel.app/",
    },
    {
      title: "Thumbnail Generator",
      description:
        "Single Page Application (SPA) focused on generating thumbnails from an uploaded image by drag and drop or through the device's camera, crop or resize the image and download the generated thumbnails.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Thumbnail_generator/Mockup-mobile_Hd_l2SCWg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654107469576",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Thumbnail_generator/mockup-tablet_NjpmyUOqu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654107472293",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Thumbnail_generator/mockup-desktop_3GNhvRH7t.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654107469626",
      },
      techs: ["Typescript", "React", "Redux", "Ant Design", "SASS"],
      repository:
        "https://github.com/Jose-Angel-Rey/thumbnail-generator-client",
      web: "https://thumbnail-generator-app.vercel.app/",
    },
    {
      title: "Rick and Morty",
      description:
        "Single Page Application (SPA) focused on displaying information from a third party API related to the Rick and Morty series, we used Sass with React and Typescript as frontend technologies and Apollo Client to consume information from a GraphQL API.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Rick-and-Morty/mobile_mx1StY8UYHf.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719960075",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Rick-and-Morty/tablet_HqhvQEtUm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719960246",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Rick-and-Morty/desktop_XChs6XvRv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719960650",
      },
      techs: ["SASS", "Typescript", "React", "Apollo"],
      repository: "https://github.com/Jose-Angel-Rey/Rick-and-Morty-app",
      web: "https://special-rick-and-morty.vercel.app/",
    },
    {
      title: "Journal App",
      description:
        "Single Page Application (SPA) that allows to create, edit, delete and view the details of the entries that are added to a personal journal, the application was developed using Sass to manage the styles of the application, Vue as Javascript framework along with Vuex to manage the global states of the application and Firebase for storing information and user authentication. ",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Journal/mobile_8w3ZCh5FE.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644180722879",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Journal/tablet_Z4F2_Gyqz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644180713586",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Journal/desktop_idvtabhDgL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644180743016",
      },
      techs: ["SASS", "Vue", "Firebase"],
      repository: "https://github.com/Jose-Angel-Rey/Journal-app",
      web: "https://my-daybook.vercel.app/",
    },
    {
      title: "Indecision App",
      description:
        "This is the first Single Page Application (SPA) that I developed using Vue as Javascript framework, its main functionality is to offer an answer to a question that the user can enter through a form field, it returns YES, NO or MAYBE. Thanks to this application I was able to understand and put into practice fundamental concepts of Vue such as declarative rendering, directives, events, event handling, Single File Components and computed properties.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Indecision-app/mobile_oaYyzDlnHl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719576023",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Indecision-app/tablet_14iMM3mm6xF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719576053",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Indecision-app/desktop_ZBSJJ9vbE.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719576765",
      },
      techs: ["SASS", "Vue", "Jest"],
      repository: "https://github.com/Jose-Angel-Rey/Indecision-app",
      web: "https://cool-indecision-app.vercel.app/",
    },
    {
      title: "Task List",
      description:
        "Single Page Application (SPA) that allows to create, edit, delete and view details of the tasks that are added to the list (CRUD), was developed using MongoDB, Express, Vue and Node (MEVN Stack) along with Sass and Typescript. ",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Task-list-MEVN/mobile_J-yMFdbKa.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719338412",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Task-list-MEVN/tablet_eX7bGmFx5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719338686",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Task-list-MEVN/desktop_X8zjhkmVk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642719338505",
      },
      techs: ["SASS", "Typescript", "Vue", "Mongo", "Express", "Node"],
      repository:
        "https://github.com/Jose-Angel-Rey/Task-list-using-MEVN-stack",
      web: "https://cool-task-list.vercel.app/",
    },
    {
      title: "Personal Portfolio",
      description: `Single Page Application (SPA) which functions as my personal portfolio showing personal information, projects I have worked on, skills and a contact form. It was developed using Sass with React and deployed using Vercel.`,
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Portfolio/mobile_3W-y00PUp8.png?updatedAt=1641380631608",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Portfolio/tablet_ASo02UjzNM.png?updatedAt=1641380631697",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Frameworks/Portfolio/desktop_YIxy71XjrHJi.png?updatedAt=1641380631658",
      },
      techs: ["SASS", "React"],
      repository: "https://jose-angel-rey.vercel.app/",
      web: "https://jose-angel-rey.vercel.app/",
      buttons: {
        repository: false,
        web: false,
      },
    },
  ];
};

export default projects();
