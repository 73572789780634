import styles from "./Skills.module.scss";
import Header from "../../components/Header/Header";
import  skills  from "../../data/skills";
import SkillsGrid from "../../components/SkillsGrid/SkillsGrid";
import { useEffect } from "react";

export default function Skills({ motion, pageTransition, pageVariants }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.main
      className={styles.container}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Header title={"Skills"} />

      <section
        className={styles.gridsContainer}
        data-aos="fade-up"
        data-aos-delay="2500"
        data-aos-duration="1500"
      >
        {
          <>
            <SkillsGrid title={"Frontend"} techs={skills.Frontend} />
            <SkillsGrid
              title={"Backend and Databases"}
              techs={skills["Backend and Databases"]}
            />
            <SkillsGrid title={"More tools"} techs={skills["More tools"]} />
          </>
        }
      </section>
    </motion.main>
  );
}
