import ProjectCategoryCard from "../ProjectCategoryCard/ProjectCategoryCard";
import styles from "./ProjectsCategories.module.scss";

export default function ProjectsCategories({ categories }) {
  return (
    <section className={styles.container}>
      <h2
        data-aos="fade-left"
        data-aos-duration="2000"
        data-aos-delay="2200"
        className={styles.title}
      >
        Choose a category
      </h2>
      <div
        className={styles.cardsContainer}
        data-aos="fade-up"
        data-aos-delay="2500"
        data-aos-duration={window.innerWidth > 1024 ? "2500" : "1500"}
      >
        {categories.map((category) => (
          <ProjectCategoryCard
            key={category.id}
            category={category.category}
            id={category.id}
            icon={category.icon}
            aos={category.aos}
          />
        ))}
      </div>
    </section>
  );
}
