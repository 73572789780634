import styles from "./SocialMedia.module.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { FaGithub, FaLinkedin } from "react-icons/fa";

export default function SocialMedia() {
  let timeline = gsap.timeline();
  let socialMedia = useRef(null);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      timeline.from(
        socialMedia,
        {
          delay: 3.5,
          duration: 1,
          opacity: 0,
          y: 30,
        }
        // "-1"
      );
    } else {
      timeline.from(
        socialMedia,
        {
          delay: 3.75,
          duration: 1.75,
          opacity: 0,
          y: -30,
        }
        // "-1"
      );
    }
  });

  return (
    <section className={styles.container} ref={(el) => (socialMedia = el)}>
      <div className={styles.links}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Jose-Angel-Rey"
        >
          <FaGithub />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jose-angel-rey/                "
        >
          <FaLinkedin />
        </a>
      </div>
    </section>
  );
}
