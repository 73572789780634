const skills = {
  Frontend: [
    {
      name: "HTML",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/html_Gahpq2BHiq.svg?updatedAt=1640445515670",
    },
    {
      name: "CSS",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/css_5bzVFwjtI.svg?updatedAt=1640445514535",
    },
    {
      name: "SASS",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/sass_LpaENJzX8.svg?updatedAt=1640445513362",
    },
    {
      name: "Bootstrap",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/bootstrap_DbIMK6kuDzS.svg?updatedAt=1640445514286",
    },
    {
      name: "Javascript",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/javascript_dMQKC4iI-_r.svg?updatedAt=1640446118447",
    },
    {
      name: "Typescript",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/typescript_yTFL84j3h.svg?updatedAt=1640446118421",
    },
    {
      name: "React",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/react_BN8f6fJoYS2.svg?updatedAt=1640445513159",
    },
    {
      name: "Redux",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/Redux_nlJVL-Ljg.svg?updatedAt=1641423603703",
    },
    // {
    //   name: "Next",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/Next_JS_MVXGb1gON.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654108701191",
    // },
    {
      name: "Ant Design",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/ant-design_SFeEY_M5x.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654108697141",
    },
    // {
    //   name: "Material UI",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/MUI_NFgwTELgi.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654108697034",
    // },
    {
      name: "Vue",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/vue_zvZo89PrABxD.svg?updatedAt=1640445513952",
    },
    // {
    //   name: "Vuetify",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/Vuetify_eqYyBpz8a.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320242",
    // },
  ],
  "Backend and Databases": [
    {
      name: "Node",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/node_RBvuUjFSuz7C.svg?updatedAt=1640445512913",
    },
    {
      name: "Express",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/express_qqBqAbj0-0.svg?updatedAt=1640445514823",
    },
    {
      name: "PostgreSQL",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/postgre_yWFcAoGK8s.svg?updatedAt=1640445513106",
    },
    {
      name: "Sequelize",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/sequelize_7IrYgApciTK7.svg?updatedAt=1640445513516",
    },
    // {
    //   name: "MongoDB",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/Mongo_z9GR_p9Fb1z.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320411",
    // },
    // {
    //   name: "GraphQL",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/GraphQL_9QyACC1G6.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320249",
    // },
    // {
    //   name: "Apollo",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/Apollo_ok9w9qnAv.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320418",
    // },
  ],
  Testing: [
    {
      name: "Jest",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/jest_YMFQcIiaj.svg?updatedAt=1640445512908",
    },
    {
      name: "Enzyme",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/enzyme_IXeSNkGVD_VP.svg?updatedAt=1640445514645",
    },
    {
      name: "Cypress",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/Cypress_aXWCj3k8Q.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654108697020",
    },
  ],
  "UI/UX Design": [
    {
      name: "Figma",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/figma_vhM4RYzqvOD3.svg?updatedAt=1640445515040",
    },
    {
      name: "Adobe XD",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/xd_0uqJNU_Ulo.svg?updatedAt=1640445514146",
    },
  ],
  "More tools": [
    {
      name: "Figma",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/figma_vhM4RYzqvOD3.svg?updatedAt=1640445515040",
    },
    {
      name: "Adobe XD",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/xd_0uqJNU_Ulo.svg?updatedAt=1640445514146",
    },
    {
      name: "Git",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/git_SJp-bqCKuY.svg?updatedAt=1640445515385",
    },
    {
      name: "GitHub",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/github_xMzW2H1yODiG.svg?updatedAt=1640445515612",
    },
    {
      name: "Jest",
      icon: "https://ik.imagekit.io/8k98kll7xyh/icons/jest_YMFQcIiaj.svg?updatedAt=1640445512908",
    },
    // {
    //   name: "Enzyme",
    //   icon: "https://ik.imagekit.io/8k98kll7xyh/icons/enzyme_IXeSNkGVD_VP.svg?updatedAt=1640445514645",
    // },
  ],
};

export default skills;
