import styles from "./Contact.module.scss";
import ContactForm from "../../components/ContactForm/ContactForm";

export default function Contact({ motion, pageVariants, pageTransition }) {
  return (
    <motion.main
      className={styles.container}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <ContactForm />
    </motion.main>
  );
}
