import gsap from "gsap";
import { useEffect, useRef } from "react";
import logo from "../../assets/logo.svg";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import styles from "./Home.module.scss";

export default function Home({ motion }) {
  let title = useRef(null);
  let subTitle = useRef(null);
  let logoAnimation = useRef(null);

  const timeline = gsap.timeline();

  useEffect(() => {
    window.scrollTo(0, 0);
    timeline.from(
      logoAnimation.current,
      {
        delay: 1,
        duration: 1.5,
        opacity: 0,
        y: 50,
      },

      "1"
    );
    timeline.from(
      title.current,
      {
        delay: 2,
        skewX: -5,
        duration: 1.5,
        opacity: 0,
        x: -50,
      },
      "1"
    );
    timeline.from(
      subTitle.current,
      {
        skewX: 5,
        delay: 2.5,
        duration: 1.75,
        opacity: 0,
        x: 50,
      },
      "1"
    );
  });

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 1,
  };

  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={styles.container}
    >
      <SocialMedia />
      <section className={styles.content}>
        <img
          className={styles.logo}
          src={logo}
          alt="logo"
          ref={logoAnimation}
        />
        <h1 className={styles.title} ref={title}>
          Jose Angel Rey
        </h1>
        <h2 className={styles.subTitle} ref={subTitle}>
          <strong>Frontend</strong> Developer
        </h2>
      </section>
    </motion.main>
  );
}
