import styles from "./FancyHeader.module.scss";
import logo from "../../assets/logo.svg";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { useEffect, useRef } from "react";
// import gsap from "gsap";

export default function FancyHeader() {
  let title = useRef(null);
  let subTitle = useRef(null);
  let logoAnimation = useRef(null);
  let socialMedia = useRef(null);

  // const timeline = gsap.timeline();

  useEffect(() => {
    window.scrollTo(0, 0);
    // timeline.from(
    //   logoAnimation.current,
    //   {
    //     delay: 1,
    //     duration: 1.5,
    //     opacity: 0,
    //     y: 50,
    //   },

    //   "1"
    // );
    // timeline.from(
    //   title.current,
    //   {
    //     delay: 2,
    //     skewX: -5,
    //     duration: 1.5,
    //     opacity: 0,
    //     x: -50,
    //   },
    //   "1"
    // );
    // timeline.from(
    //   subTitle.current,
    //   {
    //     skewX: 5,
    //     delay: 2.5,
    //     duration: 1.75,
    //     opacity: 0,
    //     x: 50,
    //   },
    //   "1"
    // );
    // timeline.from(
    //   socialMedia.current,
    //   {
    //     skewX: 5,
    //     delay: 3,
    //     duration: 1.75,
    //     opacity: 0,
    //     y: 10,
    //   },
    //   "1"
    // );
    // }, [timeline]);
  }, []);

  return (
    <header
      className={styles.container}
      data-aos="fade-up"
      data-aos-delay="2000"
      data-aos-duration="2500"
    >
      <img
        src={logo}
        alt="logo"
        ref={logoAnimation}
        // data-aos="fade-up"
        // data-aos-delay="1500"
        // data-aos-duration="2000"
      />
      <h2
        ref={title}
        // data-aos="fade-right"
        // data-aos-delay="2000"
        // data-aos-duration="2000"
      >
        Jose Angel Rey
      </h2>
      <h3
        ref={subTitle}
        // data-aos="fade-left"
        // data-aos-delay="2500"
        // data-aos-duration="2000"
      >
        <strong>Frontend</strong> Developer
      </h3>
      <nav
        className={styles.nav}
        ref={socialMedia}
        // data-aos="fade-up"
        // data-aos-delay="3000"
        // data-aos-duration="2000"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Jose-Angel-Rey"
        >
          <FaGithub />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jose-angel-rey/                "
        >
          <FaLinkedin />
        </a>
      </nav>
    </header>
  );
}
