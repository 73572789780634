import { useEffect } from "react";
import styles from "./Projects.module.scss";
import categories from "../../data/categories";
import Header from "../../components/Header/Header";
import ProjectsCategories from "../../components/ProjectsCategories/ProjectsCategories";

export default function Projects({ motion, pageVariants, pageTransition }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={styles.container}
    >
      <section className={styles.content}>
        <Header title="Projects" />
        <ProjectsCategories categories={categories} />
      </section>
    </motion.main>
  );
}
