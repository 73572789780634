const categories = () => {
  return [
    {
      category: "Javascript Frameworks",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/coding5_WU-eseh3P.jpg?updatedAt=1638753575119&tr=w-1080,h-1350,fo-auto",
      id: "Javascript Frameworks",
      icon: "fullstack",
      aos: "fade-up",
    },
    {
      category: "HTML + CSS + SASS + JavaScript",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/coding4_Gjctb7Yi8.jpg?updatedAt=1638752995238&tr=w-1080,h-1350,fo-auto",
      id: "Fundamentals",
      icon: "core",
      aos: "fade-up",
    },
    {
      category: "Frontend Mentor Challenges",
      image:
        "https://ik.imagekit.io/8k98kll7xyh/coding3_D1OF3BUL6.jpg?updatedAt=1638752991863&tr=w-1080,h-1350,fo-auto",
      id: "Frontend Mentor Challenges",
      icon: "challenges",
      aos: "fade-up",
    },
  ];
};

export default categories();
