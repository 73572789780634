const projects = () => {
  return [
    {
      title: "Testimonials slider",
      description: "Testimonials slider developed using HTML, CSS, and manipulating the DOM using Vanilla Javascript",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/slider/mobile_7qXc7d_wR.png?updatedAt=1641011228633",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/slider/tablet_Zf-2-iP9Z8W.png?updatedAt=1641011229292",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/slider/desktop_BPkLwB0vS.png?updatedAt=1641011229495",
      },
      techs: ["HTML", "CSS", "JavaScript"],
      repository: "https://github.com/Jose-Angel-Rey/Testimonials-slider",
      web: "https://cool-testimonials-slider.vercel.app/",
    },
    {
      title: "Color Flipper App",
      description:
        "Application that allows you to generate the CSS code of a random color, can generate colors by keyword, RGB, HSL, hexadecimal code and as a plus also generates the CSS code for random gradients.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/color-flipper/mobile_5V4o0qkmS.png?updatedAt=1641009294015",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/color-flipper/tablet_umzjF4idTkj.png?updatedAt=1641009294070",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/color-flipper/desktop_VJp9PkHnsp1n.png?updatedAt=1641009294101",
      },
      techs: ["HTML", "CSS", "JavaScript"],
      repository: "https://github.com/Jose-Angel-Rey/Color-flipper",
      web: "http://color-flipper-five.vercel.app/",
    },
    {
      title: "Concept for data processing company",
      description:
        "Landing page concept for data processing company developed from a Figma design using a mobile first development methodology to ensure the adaptability of the page to different screen sizes and with a friendly user interface.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/data/mobile_Ivq-Evcc0W.png?updatedAt=1641008995231",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/data/tablet_90076cC1N.png?updatedAt=1641008995227",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/data/desktop_vZ3Ry0M708X.png?updatedAt=1641008995694",
      },
      techs: ["HTML", "SASS", "JavaScript"],
      repository:
        "https://github.com/Jose-Angel-Rey/Landing-page-concept-for-data-processing-company",
      web: "https://landing-page-concept-for-data-processing-company.vercel.app/",
    },
    {
      title: "Lasles VPN",
      description:
        "Landing page for VPN services company developed from a Figma design using a mobile first development methodology to ensure the adaptability of the page to different screen sizes and with a pleasant user interface.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/lasles/mobile_FPQRtrTWxlV.png?updatedAt=1641008696150",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/lasles/tablet_Q1fTglkAsh.png?updatedAt=1641008696474",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/lasles/desktop_5uF0oXvVYY2O.png?updatedAt=1641008696573",
      },
      techs: ["HTML", "SASS", "JavaScript"],
      repository: "https://github.com/Jose-Angel-Rey/LaslesVPN-landing-page",
      web: "https://lasles-vpn-landing-page.vercel.app/",
    },
    {
      title: "CRAPPO",
      description:
        "Landing page with a cryptocurrency company concept developed from a Figma design using a mobile first development methodology to ensure the adaptability of the page to different screen sizes and with a pleasant user interface.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/crypto/mobile_xBztiYpX8K.png?updatedAt=1641008201896",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/crypto/tablet_tATiD_e02or.png?updatedAt=1641008201334",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/crypto/desktop_nU7J3_LD6E.png?updatedAt=1641008202004",
      },
      techs: ["HTML", "SASS", "JavaScript"],
      repository:
        "https://github.com/Jose-Angel-Rey/Cryptocurrency-landing-page",
      web: "https://concept-for-cryptocurrency-company.vercel.app/",
    },
    {
      title: "Product Landing Page",
      description: "Product page which served as practice to improve my CSS Grid layout skills.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/product/mobile_nv__kHOzt.png?updatedAt=1641011531219",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/product/tablet_xWz4K3PP8.png?updatedAt=1641011530855",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/product/dektop_pZE8rn95-TE.png?updatedAt=1641011531185",
      },
      techs: ["HTML", "SASS"],
      repository:
        "https://github.com/Jose-Angel-Rey/Landing-page-for-headphone-product",
      web: "https://jose-angel-rey-headphones.vercel.app/",
    },
    {
      title: "Counter App",
      description: "A common project with a unique stylThis is the classic counter application but with an out of the ordinary style and using only HTML, CSS and manipulating the DOM using Vanilla Javascript.",
      images: {
        mobile:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/counter/mobile_FE8UL8XOBnm7.png?updatedAt=1641009465407",
        tablet:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/counter/tablet_nr0coIGYBHn.png?updatedAt=1641009465424",
        desktop:
          "https://ik.imagekit.io/8k98kll7xyh/Portfolio/Core_Techs/counter/desktop_MQOYwPZ_m.png?updatedAt=1641009465498",
      },
      techs: ["HTML", "CSS", "JavaScript"],
      repository: "https://github.com/Jose-Angel-Rey/Simple-counter",
      web: "https://cool-counter.vercel.app/",
    },
  ];
};

export default projects();
