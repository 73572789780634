import styles from "./HobbieCard.module.scss";
import { BsFillPlayBtnFill } from "react-icons/bs";
import {
  MdVideogameAsset,
  MdLanguage,
  MdOutlineDirectionsWalk,
} from "react-icons/md";
import { GiOpenBook } from "react-icons/gi";
import { IoMdBrush } from "react-icons/io";

export default function HobbieCard({ icon, title }) {
  const icons = {
    travel: <BsFillPlayBtnFill />,
    gaming: <MdVideogameAsset />,
    studying: <GiOpenBook />,
    walking: <MdOutlineDirectionsWalk />,
    languajes: <MdLanguage />,
    design: <IoMdBrush />,
  };
  return (
    <article className={styles.container}>
      {icons[icon] && icons[icon]}
      <h3>{title}</h3>
    </article>
  );
}
