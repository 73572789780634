import { Link } from "react-router-dom";
import styles from "./ProjectCategoryCard.module.scss";
import { ImBooks } from "react-icons/im";
import { FaCode, FaLaptopCode } from "react-icons/fa";

export default function ProjectCategoryCard({
  category,
  // image,
  id,
  icon,
  aos,
}) {
  const icons = {
    fullstack: <FaCode />,
    core: <ImBooks />,
    challenges: <FaLaptopCode />,
  };
  return (
    <div className={styles.container}>
      <Link
        to={`/projects/${encodeURI(id)}`}
        className={styles.content}
        state={id}
      >
        {icons[icon]}
        <h2 className={styles.title}>{category}</h2>
      </Link>
    </div>
  );
}
