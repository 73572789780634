export const mainTransition = {
  type: "tween",
  ease: "linear",
  duration: 1.25,
};
export const categoryTransition = {
  type: "tween",
  ease: "linear",
  duration: .75,
};
