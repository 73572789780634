import styles from "./Alert.module.scss";
import { AiOutlineClose } from "react-icons/ai";

const Alert = ({ title, content, handleClose }) => {
  return (
    <section
      className={styles.container}
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-duration="2500"
      data-aos-delay="2500"
      data-aos-offset="0"
    >
      <div className={styles.alertContainer}>
        <AiOutlineClose
          className={styles.closeAlertButton}
          onClick={handleClose}
        />
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.content}>{content}</p>
      </div>
    </section>
  );
};

export default Alert;
