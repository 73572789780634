export const icons = {
  HTML: "https://ik.imagekit.io/8k98kll7xyh/icons/html_Gahpq2BHiq.svg?updatedAt=1640445515670",
  CSS: "https://ik.imagekit.io/8k98kll7xyh/icons/css_5bzVFwjtI.svg?updatedAt=1640445514535",
  SASS: "https://ik.imagekit.io/8k98kll7xyh/icons/sass_LpaENJzX8.svg?updatedAt=1640445513362",
  Typescript:
    "https://ik.imagekit.io/8k98kll7xyh/icons/typescript_yTFL84j3h.svg?updatedAt=1640446118421",
  JavaScript:
    "https://ik.imagekit.io/8k98kll7xyh/icons/javascript_dMQKC4iI-_r.svg?updatedAt=1640446118447",
  React:
    "https://ik.imagekit.io/8k98kll7xyh/icons/react_BN8f6fJoYS2.svg?updatedAt=1640445513159",
  Redux:
    "https://ik.imagekit.io/8k98kll7xyh/icons/Redux_nlJVL-Ljg.svg?updatedAt=1641423603703",
  Node: "https://ik.imagekit.io/8k98kll7xyh/icons/node_RBvuUjFSuz7C.svg?updatedAt=1640445512913",
  Express:
    "https://ik.imagekit.io/8k98kll7xyh/icons/express_qqBqAbj0-0.svg?updatedAt=1640445514823",
  PostgreSQL:
    "https://ik.imagekit.io/8k98kll7xyh/icons/postgre_yWFcAoGK8s.svg?updatedAt=1640445513106",
  Sequelize:
    "https://ik.imagekit.io/8k98kll7xyh/icons/sequelize_7IrYgApciTK7.svg?updatedAt=1640445513516",
  Firebase:
    "https://ik.imagekit.io/8k98kll7xyh/icons/firebase_J2y89BBy_.svg?updatedAt=1641423859987",
  Vue: "https://ik.imagekit.io/8k98kll7xyh/icons/vue_zvZo89PrABxD.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1640445513952",
  Jest: "https://ik.imagekit.io/8k98kll7xyh/icons/jest_YMFQcIiaj.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1640445512908",
  Apollo:
    "https://ik.imagekit.io/8k98kll7xyh/icons/Apollo_ok9w9qnAv.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320418",
  Mongo:
    "https://ik.imagekit.io/8k98kll7xyh/icons/Mongo_z9GR_p9Fb1z.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320411",
  Vuetify:
    "https://ik.imagekit.io/8k98kll7xyh/icons/Vuetify_eqYyBpz8a.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320242",
  GraphQL:
    "https://ik.imagekit.io/8k98kll7xyh/icons/GraphQL_9QyACC1G6.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642722320249",
  "Ant Design":
    "https://ik.imagekit.io/8k98kll7xyh/icons/ant-design_SFeEY_M5x.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654108697141",
};
