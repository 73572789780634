import styles from "./ProjectCard.module.scss";
import { FaGithub } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
import { icons } from "../../utils/icons";

export default function ProjectCard({
  title,
  description,
  images,
  web,
  repository,
  techs,
  buttons = {
    repository: true,
    web: true,
  },
}) {

  return (
    <section className={styles.container}>
      <picture className={styles.picture}>
        <source media="(min-width:1024px)" srcSet={images.desktop} />
        <source media="(min-width:768px)" srcSet={images.tablet} />
        <img src={images.mobile} alt={title} />
      </picture>

      <div className={styles.info}>
        <h2>{title}</h2>

        <p>{description}</p>

        <section className={styles.techs}>
          <h3>Tech stack:</h3>
          {techs.map((tech, index) => (
            <div key={index} className={styles.logo}>
              <img src={icons[tech]} alt={tech} />
              <span>{tech}</span>
            </div>
          ))}
        </section>
        <nav className={styles.buttons}>
          {buttons.repository && (
            <a href={repository} target="_blank" rel="noopener noreferrer">
              <FaGithub /> Repository
            </a>
          )}
          {buttons.web && (
            <a href={web} target="_blank" rel="noopener noreferrer">
              <GrLanguage /> Website
            </a>
          )}
        </nav>
      </div>
    </section>
  );
}
