import styles from "./ContactForm.module.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import FancyHeader from "../FancyHeader/FancyHeader";

export default function ContactForm() {
  let mainInputs = useRef(null);
  let textarea = useRef(null);
  let submitBtn = useRef(null);

  const timeline = gsap.timeline();

  useEffect(() => {
    window.scrollTo(0, 0);
    timeline.from(
      mainInputs.current,
      {
        delay: 3,
        duration: 1.75,
        opacity: 0,
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        x: -20,
        // scale: 0.95,
      },
      "1"
    );
    timeline.from(
      textarea.current,
      {
        delay: 3,
        duration: 1.75,
        opacity: 0,
        x: 20,
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        // scale: 0.95,
      },
      "1"
    );
    timeline.from(
      submitBtn.current,
      {
        delay: 3.5,
        duration: 1.75,
        opacity: 0,
        // y: 5,
      },
      "1"
    );
  }, [timeline]);

  async function handleSubmit(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const response = await fetch(event.target.action, {
      method: event.target.method,
      body: form,
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      event.target.reset();
      alert("Message sent successfully!");
    }
  }

  return (
    <section className={styles.container}>
      <FancyHeader />
      <form
        action="https://formspree.io/f/mgerzndj"
        method="POST"
        className={styles.form}
        id="contact-form"
        onSubmit={(event) => handleSubmit(event)}
      >
        <div className={styles.formGroup} ref={mainInputs}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            autoComplete="off"
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            autoComplete="off"
            required
          />
        </div>

        <div className={styles.formGroup} ref={textarea}>
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="6"
            placeholder="Message"
            maxLength={400}
            required
          ></textarea>
        </div>
        <button type="submit" ref={submitBtn} className={styles.button} >
          Submit
        </button>
      </form>
    </section>
  );
}
